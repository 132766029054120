import React, { useEffect, useState } from "react";
import "./Weather.css";

const Weather = ({ country }) => {

    const [weathers, setWheathers] = useState("");
    const [imgs, setImgs] = useState();
    const coutries = ['hawaii', 'korea'];

    useEffect(() => {
        const fetchWether = async () => {
            try {
                const responses = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${country}&appid=debe5b7b5a618fb62e5a0c46a3133f84&units=metric`);
                const data = await responses.json();
                const weather = data.main.temp;
                const icon = data.weather[0].icon;
                console.log(data);
                // console.log(weather);
                console.log(icon);
                setWheathers(weather);
                setImgs(`https://openweathermap.org/img/wn/${icon}@2x.png`);
            } catch {
                new Error();
            };
        };
        fetchWether();
    },);


    return (
        <>
            <div className="weather">{weathers} ℃  <img src={imgs}></img></div>

        </>

    );

};

export default Weather;
