import React, { useEffect, useRef, useState } from "react";
import "./TopMenu.css";
import Rate from "../Rate/Rate";
import Weather from "../Weather/Weather";
import Exchange from "../Exchange/Exchange";

const TopMenu = () => {

    // スタイルのJS
    const [expanded, setExpanded] = useState(false);
    const titleRef1 = useRef(null);
    const titleLinkRef1 = useRef(null);
    const titleTextRef1 = useRef(null);
    const stripCloseRef1 = useRef(null);

    const titleRef2 = useRef(null);
    const titleLinkRef2 = useRef(null);
    const titleTextRef2 = useRef(null);
    const stripCloseRef2 = useRef(null);

    useEffect(() => {
        const open = () => {
            if (!expanded) {
                titleRef1.current.classList.add('strip--expanded');
                titleTextRef1.current.style.transition = 'all .5s .3s cubic-bezier(0.23, 1, 0.32, 1)';
                stripCloseRef1.current.classList.add('strip__close--show');
                stripCloseRef1.current.style.transition = 'all .6s 0.5s cubic-bezier(0.23, 1, 0.32, 1)';
                setExpanded(true);
            }
        };

        const close = () => {
            if (expanded) {
                titleRef1.current.classList.remove('strip--expanded');
                titleTextRef1.current.style.transition = 'all 0.8s cubic-bezier(0.23, 1, 0.32, 1)';
                stripCloseRef1.current.classList.remove('strip__close--show');
                stripCloseRef1.current.style.transition = 'all 0.2s 0s cubic-bezier(0.23, 1, 0.32, 1)';
                setExpanded(false);
            }
        };

        const bindActions = () => {
            titleLinkRef1.current.addEventListener('click', open);
            stripCloseRef1.current.addEventListener('click', close);
        };

        bindActions();

        return () => {
            titleLinkRef1.current.removeEventListener('click', open);
            stripCloseRef1.current.removeEventListener('click', close);
        };
    }, [expanded]);

    // 2番目の要素に対する useEffect と bindActions も同様に設定
    useEffect(() => {
        const open = () => {
            if (!expanded) {
                titleRef2.current.classList.add('strip--expanded');
                titleTextRef2.current.style.transition = 'all .5s .3s cubic-bezier(0.23, 1, 0.32, 1)';
                stripCloseRef2.current.classList.add('strip__close--show');
                stripCloseRef2.current.style.transition = 'all .6s 0.5s cubic-bezier(0.23, 1, 0.32, 1)';
                setExpanded(true);
            }
        };

        const close = () => {
            if (expanded) {
                titleRef2.current.classList.remove('strip--expanded');
                titleTextRef2.current.style.transition = 'all 0.8s cubic-bezier(0.23, 1, 0.32, 1)';
                stripCloseRef2.current.classList.remove('strip__close--show');
                stripCloseRef2.current.style.transition = 'all 0.2s 0s cubic-bezier(0.23, 1, 0.32, 1)';
                setExpanded(false);
            }
        };

        const bindActions = () => {
            titleLinkRef2.current.addEventListener('click', open);
            stripCloseRef2.current.addEventListener('click', close);
        };

        bindActions();

        return () => {
            titleLinkRef2.current.removeEventListener('click', open);
            stripCloseRef2.current.removeEventListener('click', close);
        };
    }, [expanded]);

    return (
        <div className="topMenu">
            <article className="strip" ref={titleRef1}>
                <div className="stripContent" ref={titleLinkRef1}>
                    <h1>HAWAII</h1>
                    <div className="stripInnerText" ref={titleTextRef1}>
                        <h2>RATE</h2>
                        <Rate currency='USD' />
                        <br></br>
                        <h2>Weather</h2>
                        <Weather country="hawaii"></Weather>
                    </div>
                </div>
                <div className="stripClose" ref={stripCloseRef1}>×</div>
            </article>
            <article className="strip" ref={titleRef2}>
                <div className="stripContent" ref={titleLinkRef2}>
                    <h1>KOREA</h1>
                    <div className="stripInnerText" ref={titleTextRef2}>
                        <h2>RATE</h2>
                        <Rate currency='KRW' />
                        <br></br>
                        <h2>Weather</h2>
                        <Weather country="korea"></Weather>
                    </div>
                </div>
                <div className="stripClose" ref={stripCloseRef2}>×</div>
            </article>
        </div>
    );
};


export default TopMenu;
