import React, { useEffect, useState } from "react";
import "./Rate.css";
import Exchange from "../Exchange/Exchange";

const Rate = ({ currency }) => {

    const [rates, setRates] = useState(0);
    const currencies = ['USD', 'KRW'];



    useEffect(() => {
        const fetchRate = async () => {
            try {
                const responses = await fetch(`https://open.er-api.com/v6/latest/${currency}`);

                const data = await responses.json();

                setRates(Math.floor(data.rates.JPY * 100) / 100);

            } catch {
                new Error();
            };
        };
        fetchRate();
    }, [currency]);

    return (
        <>
            <div className="rate">{currency === "KRW" ? "1won" : "1$"} : {rates} yen</div>
            <Exchange rate={rates} currency={currency}></Exchange>
        </>

    );
};

export default Rate;
