import React, { useState } from "react";
import "./Exchange.css"

const Exchange = ({ rate, currency }) => {


    const rates = rate;

    const [jpy, setJpy] = useState("");
    const [exchangedValue, setExchangedValue] = useState();

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setJpy(inputValue);
    };

    const convertJPY = () => {
        if (!jpy) {
            setExchangedValue(0);
        }
        setExchangedValue(Math.floor((jpy / rates) * 100) / 100);
    };



    return (
        <>
            <div className="exchange">
                <input type="number" value={jpy} onChange={handleInputChange} min="0" className="inputValue" placeholder="￥0" />
                <button onClick={convertJPY} className="exchange_button">Exchange</button>
                <div>⬇︎</div>
                <div className="exchangedValue">{exchangedValue} {currency === "USD" ? "Doll" : "Won"}</div>
            </div>

        </>
    );
};

export default Exchange;